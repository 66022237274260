<template>
  <div class="pane_body">
    <v-card flat color="rgb(255 255 245)">
      <v-toolbar flat dense class="breads">
        <v-breadcrumbs :items="breadcrumb">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                x-small
                text
                v-on="on"
                color="success"
                @click.stop="newDialog = true"
            >
              new
            </v-btn>
          </template>
          <span>Create new Doctor Subscriber</span>
        </v-tooltip>
        <NewItem
            :newDialog="newDialog"
            :subType="subscriberType"
            @closeNew="closeNew"
            @submitNew="submitNew"
        />

        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              text
              v-on="on"
              color="info"
              @click.stop="uploadDialog = true"
            >
              <v-icon>mdi-cloud-upload-outline</v-icon>
            </v-btn>
          </template>
          <span>Upload</span>
        </v-tooltip> -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" color="info" @click="lists()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-toolbar>

    </v-card>

    <v-container>
      <v-card color="transparent" flat>
        <v-card flat rounded="0">
          <v-card-text>
            <v-data-table
                :loading="loading"
                loader-height="2"
                :headers="headers"
                :items="dataList"
                :items-per-page="8"
                :footer-props="{ itemsPerPageOptions: [8, 16, 24] }"
            >
              <template v-slot:item.updated="{ item }">
                {{ item.updated | myDating }}
              </template>

              <template v-slot:item.active="{ item }">
                <v-icon :color="item.active ? 'blue' : 'red'">
                  {{ item.active ? "mdi-check" : "mdi-close" }}
                </v-icon>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                    @click.stop="$set(viewDialog, item.id, true)"
                    text
                    x-small
                    color="primary"
                >
                  view
                </v-btn>
                <ViewItem
                    v-if="viewDialog[item.id]"
                    :myId="item.id"
                    :viewDialog="viewDialog[item.id]"
                    @closeView="closeView"
                />

                <v-btn
                    @click.stop="$set(editDialog, item.id, true)"
                    text
                    x-small
                    color="success"
                >
                  edit
                </v-btn>
                <EditItem
                    v-if="editDialog[item.id]"
                    :myId="item.id"
                    :editDialog="editDialog[item.id]"
                    @closeEdit="closeEdit" @submitEdit="submitEdit"/>

                <v-btn
                    @click.stop="$set(removeDialog, item.id, true)"
                    text
                    x-small
                    color="error"
                >
                  delete
                </v-btn>
                <RemoveItem
                    v-if="removeDialog[item.id]"
                    :myId="item.id"
                    :removeDialog="removeDialog[item.id]"
                    @closeRemove="closeRemove"
                    @submitRemove="submitRemove"
                />

              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <!--        <a href="https://my.umohe.com" target="_blank">Umohe Portal</a>-->
      </v-card>
    </v-container>
    <v-snackbar
        :color="snack.color"
        v-model="snack.bar"
        :multi-line="snack.multiLine"
        timeout="-1"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils";
import NewItem from "./crud/newone.vue";
import EditItem from "./crud/editing.vue";
import ViewItem from "./crud/view.vue";
import RemoveItem from "./crud/remove.vue";

export default {
  mixins: [Utils],
  components: {NewItem, EditItem, ViewItem, RemoveItem},
  data() {
    return {
      newDialog: false,
      viewDialog: {},
      editDialog: {},
      removeDialog: {},
      loading: false,
      uploadDialog: false,
      subscriberType: 5,
      breadcrumb: [
        {
          text: "Dashboard",
          disabled: false,
          to: {name: "admin.dash"},
        },
        {
          text: "Welcome Mails",
          disabled: true,
        },
      ],
      headers: [
        {text: "template type", value: "template_type"},
        {text: "subscriber types", value: "subscriber_types"},
        {text: "active", value: "active"},
        {text: "updated", value: "updated"},
        {text: "actions", value: "actions", align: "center"},
      ],
      dataList: [],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
    };
  },
  created() {
    this.lists();
  },
  methods: {
    lists() {
      this.loading = true;
      let self = this;
      Restful.templates.mails.list()
          .then((response) => {
            console.log('templates', response);
            if (response.status === 200) {
              this.dataList = response.data;
              this.loading = false;
            } else {
              this.loading = false;
            }
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      console.log(p);
      this.snack.bar = true;
      // let self = this;
      Restful.templates.mails
          .create(p.data)
          .then((response) => {
            if (response.status === 200) {
              this.snack.color = "success";
              this.snack.text = response.data;
              this.lists();
            }

          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.newDialog = p.state;
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeEdit(p) {
      console.log('p', p)
      let self = this;
      Restful.templates.mails
          .update(p.id, p.data)
          .then((response) => {
            console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data.status
                ? "Successfully saved"
                : "Did not save";
            this.lists();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      this.snack.bar = true;
      this.snack.color = "info";
      this.snack.text = "Processing";
      console.log(p.data);
      let self = this;
      Restful.templates.mails
          .update(p.id, p.data)
          .then((response) => {
            if (response.status === 200) {
              this.snack.color = "success";
              this.snack.text = response.data;
              this.lists();
            }
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });

      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      this.snack.color = "info";
      this.snack.text = "Processing";
      console.log('hey', p.id);
      let self = this;
      Restful.templates.mails
          .delete(p.id)
          .then((response) => {
            console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.lists();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });

      this.$set(this.removeDialog, p.id, p.state);
    },
  },
};
</script>
  