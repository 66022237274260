<template>
  <v-dialog v-model="editDialog" persistent max-width="1000">
    <v-card>
      <v-card-title>New message template</v-card-title>
      <v-card-text>
        <v-select
            dense
            label="subscriber types"
            v-model="view.subscriber_typesid"
            :items="subTypes"
            item-text="name"
            item-value="id"
        ></v-select>

        <v-select
            dense
            label="template types"
            v-model="view.template_type"
            :items="templateTypes"
            item-text="name"
            item-value="name"
        ></v-select>

        <mavon-editor
            placeholder=""
            language="en" v-model="view.message"/>
        <div style="margin: 7px 0; font-size: .8em">
          Use <b>first_name</b> and/or <b>last_name</b> as a placeholder for the recipients
          name within this message.
        </div>

        <!--        <v-textarea
                    label="message"
                    v-model="view.message"
                    placeholder=""
                    hint="Use first_name and/or last_name as a placeholder for the recipients name within this message."
                ></v-textarea>-->

        <v-switch
            v-model="view.active"
            :label="`Active: ${view.active.toString()}`"
        ></v-switch>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                small
                text
                color="blue"
                v-on="on"
                @click="submit"
                :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {validationMixin} from "vuelidate";
import Restful from "@/services/RestFul";
import {
  required,
} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {
    editDialog: Boolean,
    myId: Number,
  },
  data() {
    return {
      subTypes: [],
      templateTypes: [],
      view: {
        subscriber_typesid: null,
        template_type: null,
        message: null, active: false
      },
    };
  },
  validations: {
    view: {
      subscriber_typesid: {
        required,
      },
      template_type: {
        required,
      },
      message: {
        required,
      },
    },
  },
  watch: {},
  created() {
    this.subscriberTypes();
    this.templateTpe();
    this.find();
  },
  methods: {
    find() {
      Restful.templates.mails
          .find(this.myId)
          .then((response) => {
            console.log('subscriberTypes', response);
            this.view.subscriber_typesid = response.data.subscriber_typesid;
            this.view.template_type = response.data.template_type;
            this.view.message = response.data.message;
            this.view.active = response.data.active;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    subscriberTypes() {
      Restful.general.subscriberTypes
          .active(true)
          .then((response) => {
            console.log('subscriberTypes', response);
            this.subTypes = response.data;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    templateTpe() {
      Restful.general.templates
          .types()
          .then((response) => {
            console.log('templateTpe', response);
            this.templateTypes = response.data;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeEdit", payload);
    },
    submit() {
      // console.log(this.view);
      let subscriber_typesid = this.view.subscriber_typesid;
      let template_type = this.view.template_type;
      let message = this.view.message;
      let active = this.view.active;

      let data = {
        subscriber_typesid,
        template_type,
        message, active
      };
      console.log("data:", JSON.stringify(data));

      let payload = {state: false, id: this.myId, data: data};
      console.log("payload:", payload);
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
